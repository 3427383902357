
































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { UserListObj, UserAddParams, Department } from './types'
import { Form } from 'element-ui'

@Component({ name: 'UserSetting' })
export default class DepartmentList extends VueBase {
  private addDialogOpen = false
  private page = 1
  private allTotal = 0
  private isAdd = true
  private options = []
  private roleOptions = []
  private keyword = ''
  private form = {
    name: '',
    region: '',
    value: true,
  }

  private newSelectData() {
    this.page = 1
    this.getTableData()
  }
  private rules = {
    username: [
      {
        required: true,
        message: this.$t('views.userList.namePlaceholder'),
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: this.$t('views.userList.emailPlaceholder'),
        trigger: 'blur',
      },
    ],
    role: [
      {
        required: true,
        message: this.$t('views.userList.rolePlaceholder'),
        trigger: 'blur',
      },
    ],
    department: [
      {
        required: true,
        message: this.$t('views.userList.department1Placeholder'),
        trigger: 'blur',
      },
    ],
    phone: [
      {
        required: true,
        message: this.$t('views.userList.phonePlaceholder'),
        trigger: 'blur',
      },
    ],
    password: [{ validator: this.validateNewPass, trigger: 'blur' }],
    re_password: [{ validator: this.validateCheckPass, trigger: 'blur' }],
  }
  private validateNewPass(rule: any, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.needPWD') as string))
    } else {
      if (this.userForm.password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('re_password')
      }
      callback()
    }
  }

  private async resetPwd(item: any) {
    this.$confirm(
      this.$t('views.userList.resetConfirm') as string,
      this.$t('views.userList.resetConfirmPop') as string,
      {
        confirmButtonText: this.$t('views.userList.submit') as string,
        cancelButtonText: this.$t('views.userList.cancel') as string,
        type: 'warning',
      }
    ).then(async () => {
      const { status, msg } = await this.services.user.reset({
        userId: item.id as number,
      })
      if (status === 201) {
        this.$message({
          type: 'success',
          message: msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
      }
    })
  }

  private async userDelete(uid: number) {
    this.$confirm(
      this.$t('views.userList.deleteConfirm') as string,
      this.$t('views.userList.deleteConfirmPop') as string,
      {
        confirmButtonText: this.$t('views.userList.submit') as string,
        cancelButtonText: this.$t('views.userList.cancel') as string,
        type: 'warning',
      }
    ).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.user.userDelete({
        uid,
      })
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
      await this.getTableData()
    })
  }
  private validateCheckPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.rePWD') as string))
    } else if (value !== this.userForm.password) {
      callback(new Error(this.$t('views.userList.diffPWD') as string))
    } else {
      callback()
    }
  }
  private handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  private tableData = []
  async getTableData() {
    this.loadingStart()
    const res = await this.services.user.userList({
      page: this.page,
      pageSize: 10,
      keywords: this.keyword,
    })
    this.loadingDone()
    if (res.status === 201) {
      this.tableData = res.data
      this.allTotal = res.page.alltotal
    } else {
      this.$message.error(res.msg)
    }
  }

  private userForm: any = {
    username: '',
    password: '',
    re_password: '',
    email: '',
    role: '',
    department: '',
    phone: '',
    uid: 0,
  }

  private addDialogShow() {
    this.userForm = {
      username: '',
      password: '',
      re_password: '',
      email: '',
      role: '',
      department: '',
      phone: '',
      uid: 0,
    }
    this.addDialogOpen = true
  }

  private userEdit(row: any) {
    this.isAdd = false
    this.userForm = {
      username: row.username,
      password: '',
      re_password: '',
      email: row.email,
      role: row.role_id,
      department: row.department.id,
      phone: row.phone,
      uid: row.id,
    }
    this.addDialogOpen = true
  }
  private cancelAdd() {
    this.addDialogOpen = false
    if (this.isAdd === false) {
      this.isAdd = true
    }
  }
  private userAdd() {
    // console.log(this.userForm.department)
    ;(this.$refs.ruleForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: UserAddParams = {
          username: this.userForm.username,
          password: this.userForm.password,
          re_password: this.userForm.re_password,
          email: this.userForm.email,
          role: this.userForm.role,
          department: this.userForm.department,
          phone: this.userForm.phone,
        }
        if (this.userForm.uid) {
          params.uid = this.userForm.uid
        }
        // console.log(params)
        this.loadingStart()
        if (this.isAdd) {
          const { status, msg } = await this.services.user.userAdd(params)
          this.loadingDone()
          if (status !== 201) {
            this.$message({
              type: 'error',
              message: msg,
              showClose: true,
            })
            return
          }
          this.$message.success(msg)
        } else {
          const { status, msg } = await this.services.user.userEdit(params)
          this.loadingDone()
          if (status !== 201) {
            this.$message({
              type: 'error',
              message: msg,
              showClose: true,
            })
            return
          }
          this.$message.success(msg)
        }
        this.addDialogOpen = false
        this.getTableData()
        this.isAdd = true
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  fmtOptions(options: Array<any>) {
    options.forEach((item: any) => {
      if (item.id < 0) {
        item.disabled = true
      }
      item.value = { name: item.label, id: item.id }
      if (item.children.length) {
        this.fmtOptions(item.children)
      } else {
        delete item.children
      }
    })
  }
  async departmentList() {
    const res = await this.services.user.departmentList()
    if (res.status === 201) {
      this.fmtOptions(res.data)
      this.options = res.data
    }
  }

  private async getRoles() {
    const res = await this.services.role.getRoles({
      page: 1,
      page_size: 100,
    })
    if (res.status === 201) {
      this.roleOptions = res.data
    } else {
      this.$message.error(res.msg)
    }
  }
  created() {
    this.getRoles()
    this.getTableData()
    this.departmentList()
  }
}
